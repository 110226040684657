export default {
    
        pageDesign:{
            pageStyles:{
               activeSubBlock: false,
alignItems: "center",
backgroundColor: "#ffffff",
bgImageSrc: false,
bgImageStatus: false,
color: "black",
fontFamily: "",
fontSize: "",
height: 500,
imageAlign: "right",
imageFor: "both",
imageSrc: "",
marginLeft: 0,
marginRight: 0,
paddingBottom: 5,
paddingLeft: 5,
paddingRight: 5,
paddingTop: 5,
textAlign: "right",
width: 850,
    
    },
    
    blocksArray: [
      {
        type: "text",
        content: "Quiz Example Display",
        style: {
          paddingLeft: 30,
          paddingRight: 10,
          paddingTop: 50,
          paddingBottom: 10,
          marginTop: 50,
          marginBottom: 10,
          marginLeft:10,
          marginRight: 10,
          width: 800,
          textOpacity: 100,
          color: "#000000",
          rotate: 0,
          lineHeight: 1.2,
          textDecoration: "unset",
          fontWeight: "bold",
          fontStyle: "normal",
          textAlign: "initial",
          fontSize: 34,
          fontFamily: "Poppins",
        },
        customizeStatus: false,
        hoverStatus: false,
      },
      {
        type: "text",
        content:
          "Get a glimpse of how your quiz will appear to users.",
        style: {
          paddingLeft: 30,
          paddingRight: 10,
          paddingTop: 10,
          paddingBottom: 10,
          marginTop: 10,
          marginBottom: 10,
          marginLeft: 10,
          marginRight: 10,
          fontSize: 18,
          width: 800,
          height: 100,
          textOpacity: 100,
          color: "#5d5858",
          rotate: 0,
          lineHeight: 1.5,
          textDecoration: "unset",
          fontWeight: "bold",
          fontStyle: "normal",
          textAlign: "initial",
          fontFamily: "Poppins",
        },
        customizeStatus: false,
        hoverStatus: false,
      },
      
      {
        type: "button",
        content: 'this is button',
        text:'Get Started',
        style: {
          backgroundColor: "#000000",
backgroundOpacity: 100,
borderColor: "",
borderRadius: 0,
borderSize: "0",
buttonHeight: 54,
buttonWidth: 150,
color: "#ffffff",
fontFamily: "Poppins",
fontSize: "16",
fontStyle: "normal",
fontWeight: "normal",
lineHeight: 1,
marginBottom: 10,
marginLeft: 40,
marginRight: 10,
marginTop: 10,
paddingBottom: 15,
paddingLeft: 100,
paddingRight: 100,
paddingTop: 15,
position: "left",
textAlign: "left",
textDecoration: "unset",
textOpacity: 100,
widthIsAuto: true,
        },
        customizeStatus: false,
        hoverStatus: false,
      },
    ],
    imageBlock: {
      // src: "https://images.quizell.com/gallery/4521688728379.png",
      src: "https://images.unsplash.com/photo-1612293905607-b003de9e54fb?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      customizeStatus: false,
      hoverStatus: false,
      style: {
          backgroundColor: "none",
displayDesktop: true,
displayMobile: true,
height: 250,
imageOpacity: 100,
paddingBottom: 10,
paddingLeft: 10,
paddingRight: 10,
paddingTop: 10,
rotate: 0,
textAlign: "center",
width: 300,
      },
    },
        },
        PopUpDesigns:{
            buttonText:'Start Quiz',
            popUpSize:'Large',
            popUpWidth:800,
            popUpHeight:500,
            fontSize:16,
            buttonColor:'#000000',
            borderRadius:14,
            buttonTextColor:"#ffffff"
        },
        GreetBarDesigns:{
            buttonText:'Start Quiz',
            popUpSize:'Large',
            popUpWidth:800,
            popUpHeight:500,
            fontSize:16,
            buttonColor:'#000000',
            borderRadius:14,
            buttonTextColor:"#ffffff",
            greetBarText:'You are intrested',
            greetBarTextColor:'#ffffff',
            greetBarBGColor:'#ffa200',
        },
        floatingButtonDesign:{
buttonText:'Start Quiz',
            popUpSize:'Large',
            popUpWidth:800,
            popUpHeight:500,
            fontSize:16,
            buttonColor:'#000000',
            borderRadius:14,
            buttonTextColor:"#ffffff",
            buttonPos:"Right"
        }
}