import axios from 'axios'
export default{
    methods:{
   async   ValidateQuizKey(){

                let data = {
                 quizKey : this.$route.params.id
                //  quizKey : quizKey.slice(18)
               };
               const response = await axios.post(`/validateQuizKey`, data);
               return response
        }
  
    }
}
